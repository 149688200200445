<template>
  <div class="info-header-wrapper">
    <div class="left">
      <div class="info-box">
        <span>设备名称：</span>
        <span> {{detailInfo.deviceName}}</span>
      </div>
      <div class="info-box">
        <span>设备编号：</span>
        <span> {{detailInfo.deviceNo}}</span>
      </div>
      <div class="info-box">
        <span>工单编号：</span>
        <span> {{detailInfo.orderCode}}</span>
      </div>
      <div class="info-box">
        <span>工单状态：</span>
        <span> {{detailInfo.orderStatusName}}</span>
      </div>
    </div>
    <div class="right">
      <div class="icon">
        <img :src="imgUrl + detailInfo.photoUrl" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { imgUrl } from '@/config/env'
export default {
  name: "InfoHeader",
  props: {
    detailInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      imgUrl
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.info-header-wrapper {
  @include wh(100%, auto);
  background: linear-gradient(
    90deg,
    rgba(79, 147, 255, 1) 0%,
    rgba(58, 187, 253, 1) 100%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.8rem 1rem 0.8rem 1.5rem;
  .left {
    width: 0;
    flex: 1;
    .info-box {
      display: flex;
      flex-direction: row;
      span {
        font-size: 0.6rem;
        color: white;
        line-height: 1.5rem;
        display: block;
      }
      span:nth-child(2) {
        width: 0;
        flex: 1;
        word-wrap: break-word;
        word-break: normal;
      }
    }
  }
  .right {
    .icon {
      @include wh(5rem, 5rem);
      background-color: white;
      img {
        @include wh(5rem, 5rem);
      }
    }
  }
}
</style>
