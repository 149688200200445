<template>
  <div class="info-fault-wrapper">
    <ul>
      <li>
        <div class="info-wrapper">
          <span>保养计划：</span>
          <span>{{detailInfo&&detailInfo.planName}}</span>
        </div>
      </li>
      <li>
        <div class="info-wrapper">
          <span>保养项目：</span>
          <span>{{detailInfo&&detailInfo.maintainItemName}}</span>
        </div>
      </li>
      <li>
        <div class="info-wrapper">
          <span>保养方法：</span>
          <span>{{detailInfo&&detailInfo.maintainMethod}}</span>
        </div>
      </li>
      <li>
        <div class="info-wrapper">
          <span>保养标准：</span>
          <span>{{detailInfo&&detailInfo.approvalStandard}}</span>
        </div>
      </li>
      <li>
        <div class="info-wrapper">
          <span>计划时间：</span>
          <span>{{detailInfo&&(detailInfo.maintainStartTime+' ~ '+detailInfo.maintainEndTime)}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { Field, Picker, Popup } from 'vant'
Vue.use(Picker)
Vue.use(Field)
Vue.use(Popup)
export default {
  name: 'InfoFault',
  components: {
  },
  data() {
    return {
      query: null,
      stopInterval: '',
    }
  },
  props: {
    detailInfo: {
      type: Object,
      default() {
        return null
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.query = this.$route.query
    })
  },
  watch: {
    detailInfo: {
      handler(val) {
        if (val) {
          this.stopInterval = val.stopInterval
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() { },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.info-fault-wrapper {
  ul {
    li {
      padding: 0.45rem 0;
      border: 1px solid #ebedf0;
      .info-wrapper {
        padding: 0 1.5rem;
        display: flex;
        flex-direction: row;
      }
      span {
        color: #333;
        font-size: 0.6rem;
        font-weight: bold;
      }
      span:nth-child(1) {
        padding-right: 0.2rem;
      }
      span:nth-child(2) {
        width: 0;
        flex: 1;
        word-wrap: break-word;
        word-break: normal;
      }
    }
  }
}
</style>
