<template>
  <div class="remark-wrapper">
    <template v-if="hasList">
      <div class="remark-box" v-for="(item,index) in detailInfo.childList" :key="index">
        <div class="remark-info">
          <i class="icon"></i>
          <span class="status"> {{item.status}}</span>
          <span class="time">
            {{item.time}}
          </span>
        </div>
        <div class="remark-detail">
          <span>{{item.names}} <label v-if="item.remark">{{' ：'+item.remark}}</label> </span>
          <template v-if="item.photoUrl">
            <van-image v-for="items in item.photoUrl.split(',')" :key="items" width="2.5rem" height="2.5rem" :src="imgUrl + items" />
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="remark-box"><span class="remark-box">无</span></div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { Field, Picker, Popup, Image as VanImage } from 'vant'
Vue.use(Picker)
Vue.use(Field)
Vue.use(Popup)
Vue.use(VanImage)
import { imgUrl, QiNiuUrl } from '@/config/env'

export default {
  name: 'InfoFault',
  components: {
  },
  data() {
    return {
      imgUrl,
      query: null,
      hasList: true
    }
  },
  props: {
    detailInfo: {
      type: Object,
      default() {
        return null
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.query = this.$route.query
    })
  },
  watch: {
    detailInfo: {
      handler(val) {
        if (val.childList && val.childList.length != '0') {
          this.hasList = true
        } else {
          this.hasList = false

        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() { },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.remark-box {
  padding: 0.5rem 1rem;
  .remark-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      background: #409eff;
      margin-right: 0.5rem;
    }
    .status {
      color: #333333;
      font-weight: bold;
      font-size: 0.7rem;
    }
    .time {
      width: 0;
      flex: 1;
      font-size: 0.5rem;
      font-weight: bold;
      text-align: right;
    }
  }
  .remark-detail {
    padding: 0.2rem 0 0.2rem 0.8rem;
    span {
      font-size: 0.5rem;
      font-weight: bold;
      display: block;
      label {
        font-weight: 400;
        font-size: 0.5rem;
      }
    }
    .van-image {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0.5rem 0.5rem 0 0;
    }
  }
}
</style>
