<template>
  <div class="repair-info-wrapper">
    <div class="info-box">
      <van-collapse v-model="activeNames">
        <van-collapse-item name="1">
          <template #title>
            <Title title="设备信息" />
          </template>
          <InfoHeader :detailInfo="detailInfo" />
        </van-collapse-item>
        <van-collapse-item name="2">
          <template #title>
            <Title title="保养信息" />
          </template>
          <InfoMaintain ref="infoMaintain" :detailInfo="detailInfo" />
        </van-collapse-item>
        <van-collapse-item name="3">
          <template #title>
            <Title title="保养详情" />
          </template>
          <InfoRepairRead ref="infoRepairRead" :detailInfo="detailInfo" v-if="readOnly" />
          <InfoRepair ref="infoRepair" :detailInfo="detailInfo" v-else />
        </van-collapse-item>
        <van-collapse-item name="4">
          <template #title>
            <Title title="处置意见" />
          </template>
          <RemarkMaintain ref="remarkMaintain" :detailInfo="detailInfo" />
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="btn-box">
      <Button @handleButtonClick="handleSubmitClick" v-show="btnShow">{{btnContent}}</Button>
    </div>

    <Dialog :show="show" :info="dialogInfo" @handleCloseDialogClick="handleCloseDialogClick" @handleQueryDialogClick="handleQueryDialogClick" />

  </div>
</template>

<script>
import Vue from 'vue'
import Title from '@/components/Title'
import Button from '@/components/Button'
import Dialog from '@/components/Dialog'

import InfoHeader from './components/InfoHeader'
import InfoMaintain from './components/InfoMaintain'
import InfoRepair from './components/InfoRepair'
import InfoRepairRead from './components/InfoRepairRead'
import RemarkMaintain from './components/RemarkMaintain'
import { Toast, Collapse, CollapseItem } from 'vant'
import { finishOrder, resubmitOrder, supplementOrder, maintainOrderDetail } from '@/api/maintain'

Vue.use(Toast)
Vue.use(Collapse)
Vue.use(CollapseItem)
import 'vant/lib/collapse/style'
import 'vant/lib/collapse-item/style'

export default {
  name: 'RepairInfo',
  components: {
    Title,
    Button,
    Dialog,
    InfoHeader,
    InfoMaintain,
    InfoRepairRead,
    InfoRepair,
    RemarkMaintain,


  },
  data() {
    return {
      show: false,
      dialogInfo: {
        question: '该工单已超出可执行时间范围，是否申请补单？',
        cancerTxt: '取消',
        queryTxt: '确定',
      },
      detailInfo: {},
      query: null,
      activeNames: ['1', '4'],
      readOnly: true,
      btnContent: '提交',
      btnShow: true
    }
  },
  created() {
    this.$nextTick(() => {
      this.query = this.$route.query
      this.maintainOrderDetail()
    })
  },
  watch: {
    detailInfo(val) {
    },
  },
  mounted() { },
  methods: {
    /**
     * 获取设备保养信息
     */
    maintainOrderDetail() {
      maintainOrderDetail(this.query.id).then((res) => {
        let details = res.details
        this.detailInfo = details
        switch (details.orderStatus) {
          case -2:
            this.readOnly = true;
            this.btnShow = true;
            this.btnContent = '申请补单';
            break;
          case 1:
          case 2:
            this.readOnly = false;
            break;
          case 3:
          case 4:
            this.readOnly = true;
            this.btnShow = false;
            break;
          case 5:
            this.readOnly = true;
            this.btnShow = true;
            this.btnContent = '再次提交';
            break;
        }
      })
    },
    /**
     * 提交
     */
    handleSubmitClick() {
      if (this.btnContent == '申请补单') {
        this.show = true
        return
      } else 
      if (this.btnContent == '再次提交') {
        this.btnContent = '提交'
        this.readOnly = false
      }


      let params = {}
      let { maintenanceContent,
        maintenanceApprovalUserIds,
        finishUserIds,
        repairFileList,
        finishStartTime, finishEndTime, } = this.$refs.infoRepair.$data
      if (!maintenanceContent) {
        Toast('请输入保养内容！')
        return
      }
      let finishUserId = []
      finishUserIds.forEach((item) => {
        finishUserId.push(item.id)
      })
      let maintenanceApprovalUserId = []
      maintenanceApprovalUserIds.forEach((item) => {
        maintenanceApprovalUserId.push(item.id)
      })
      let maintenancePhoto = []
      repairFileList.forEach((item) => {
        maintenancePhoto.push(item.hash)
      })
      params = {
        finishStartTime,
        finishEndTime,
        // maintenanceContent,
        maintainContent: maintenanceContent,
        maintainPhotos: maintenancePhoto.join(','),
        // maintenancePhotos: maintenancePhoto.join(','),
        finishUserIds: finishUserId.join(','),
        approvalUserIds: maintenanceApprovalUserId.join(','),
      }
      if (this.detailInfo.orderStatus == '-2') {
        supplementOrder(this.query.id, params).then((res) => {
          if(res.retCode=='1'){
             this.$router.push({
              path: '/maintain/suc',
            })
          }else{
              Toast(res.retMsg)
        return
          }
        })
      } else if (this.detailInfo.orderStatus == '5') {
        resubmitOrder(this.query.id, params).then((res) => {
            if(res.retCode=='1'){
               this.$router.push({
            path: '/maintain/suc',
          })
          }else{
              Toast(res.retMsg)
            return
          }
         
        })
      } else {
        finishOrder(this.query.id, params).then((res) => {
          if(res.retCode=='1'){
             this.$router.push({
            path: '/maintain/suc',
          })
          }else{
              Toast(res.retMsg)
        return
          }
         
        })
      }

    },


    handleCloseDialogClick() {
      this.show = false
    },
    handleQueryDialogClick() {
      this.show = false
      this.btnContent = '提交'
      this.readOnly = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.repair-info-wrapper {
  min-height: 100%;
  box-sizing: border-box;
  background-color: #f1f1f1;
  padding-bottom: 3rem;
  position: relative;
  .title-box {
    box-sizing: border-box;
    padding: 0 1rem;
  }
  .btn-box {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
  }
  /deep/.info-box {
    .van-collapse {
      .van-collapse-item {
        .van-cell {
          padding: 0 1rem;
          background: transparent;
          .van-icon {
            height: 2rem;
            line-height: 2rem;
          }
        }
      }
      .van-collapse-item__wrapper {
        .van-collapse-item__content {
          padding: 0;
        }
      }
    }
  }
}
</style>
